@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

.App {
  min-height:100vh;
  text-align: center;
  display: flex;
  flex-direction:column;
  overflow: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Montserrat', sans-serif;
}

.body {
  margin: 0 auto;
  padding-bottom: 5vmax;
  color: #004eb3;
  padding: 2vh 2vw;
}

header {
  margin:0 auto;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  border-radius: 0 0 0.2em 0.2em;
  overflow: visible;
  width: 100%;
  max-width: 1050px;
  background-attachment: scroll;
}

.header-image {
  width: 45%;
  height: auto;
}
nav {
  justify-content: center;
  color:black;
  background-color:rgb(241,209,0);
}
.navbar .container {
  flex-wrap: nowrap;
}
.navbar-brand {
  display: flex;
}
#hero-img {
  box-sizing: border-box;
  padding:1vh 1vw;
  width:100vw;
}
li {
  text-align: start;
}
#notice {
  color: black;
  font-weight: bold;
}
.beard-dashboard {
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
}
.loading-beard {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.beard-row {
  margin: 2vh 0;
  max-height:33vh;
  height:28vh;
  width:100%;
  display:flex;
  flex-direction:row;
  color:black;
  align-items: center;
  background-color: rgba(0, 100, 255, 0.2);
  border-radius:15px;
}
.beard-row > * {
  margin: 2vw;
}
.beard-icon {
  width:25vw;
  border-radius: 50%;
  border: 3px solid black;
  max-height:20vh;
}
#instructions > li {
  font-size: large;
}
.kitchen-sink {
  width:60vw;
  height:23vh;
}
.graph-container {
  max-width:50vw;
  margin-bottom:1vh;
}
.bar-graph {
  background-color: #0d01f1;
  color:white;
  height:100%;
  width:100%;
}
.graph-amount {
  height:100%;
  margin: auto;
  display:inline-block;
  padding:3vh 0;
}
.donate-button-container {
  margin: 0 auto;
  max-height:10vh;
  width:100%;
}
.donate-button-container > * {
  margin:0 auto;
  width:60vw;
}

.MatchupModal {
  justify-items: center;
}

.MatchupModal p {
  font-size: large;
  vertical-align:middle;
}
.suggested-donation {
  max-width:15%;
}

.MatchupModal h3 {
  display: flex;
  justify-content: center;
}

.MatchupModal .Face-style {
  padding-left: 10%;
}

.stylishbutton {
  font-family: 'Montserrat', sans-serif;
  background-color: #004eb3;
  color: #fefefe;
  border-radius: .5em;
  border: .4vw solid #ffffff;
  font-size: calc(10px + 6 * ((100vw - 320px) / 680));
  padding: 2vw 4vw;
}

.modal-content {
  justify-items: center;
  color: #fefefe;
}

.modal-body {
  background-color: #004eb3;
}

.modal-header {
  background-color: rgb(241, 209, 0);
  color: #004eb3;
}

.modal-footer {
  background-color: rgb(241, 209, 0);
  color: #004eb3;
}

footer {
  width:100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#fblogo {
  max-height: 5vmax;
  justify-self: right;
}

.dollars {
  font-size: calc(8px + 6 * ((100vw - 320px) / 680));
}

.Face-style {
  width: 90%;
  max-width: 150px;
  margin-bottom: -15%;
}

.announcement {
  margin: 0 3vw;
}

.Container .col {
  width: 10%;
}

.charity-logo-grp {
  width:100%;
}

.charity-logo {
  width:100%;
  max-width:40vw;
  padding:0 2vw;
}

#email-form {
  margin: 4vh 2vw;
  padding: 4vh 4vw;
  /* border:2px solid black; */
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.551)
}

.btn-email {
  margin-left: 15px;
}

label {
  margin-right: 15px;
}

.beard-label {
  margin-left:40%;
  width:60%;
  display: flex;
  flex-direction:column;
  text-align: left;
}

.spinner-border .no-donate {
  margin-left:50%;
}

  .beard-chart {
    background-color: rgba(255, 255, 255, 0.819);
    border-radius: 15px;
    padding:2vh 2vw;
  }
  #chart-total, #chart-total > * {
    margin-bottom: 0
  }
  .chart-row {
    display: flex;
    flex-direction: row;
    max-height: 18vh;
  }
  .chart-row > * {
    margin:1vh 1vw;
  }
  .beardChart-icon-container, .icon-and-name {
    width:25vw;
  }
  .beardChart-icon {
    border-radius: 50%;
    border: 3px solid black;
    max-height:13vh;
    max-width:25vw;
  }
  .beardChart-name {
    font-size: large;
  }
  .chart-container {
    width:63vw;
  }
  iframe {
    width:100%;
    height:75vh;
  }
  #sponsors-view img {
    max-width:100%;
    padding:1vh 1vw;
  }
  #sponsor-logos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .goal-meter-container {
    background-color:#ffffff;
    border:2px solid black;
    border-radius: 15px;
    display:flex;
    flex-direction: row;
  }
  .meter-bar {
    margin: 0.25vh 0;
    /* height: 100%; */
    background-color: blue;
    border-radius: 15px;
  }
  .meter-amount {
    font-size: x-large;
    color:white;
  }
  #amount-outside {
    color:black;
  }
  #leader-label {
    margin: 1vh 0 0 0;
    font-weight: bold;
    color:blue
  }

  #landing-buttons button {
    border:none;
    background-color: #ffffff;
    color: navy;
    font-weight:bold;
    padding:0;
  }

  .active {
    font-weight: bold;
  }

  #planlogic-logo {
    background-color: #7abaf2;
  }
  
  .performers, .vendor-logos {
    display: flex;
    flex-wrap: wrap;
  }

  .performers > div > a > img, .performers > div > img, .vendor-logos > div > a > img {
    width:100%;
  }

  .stream-embed {
    width:100%;
    height:50vh;
  }
  .header-image-small {
    height:5vh;
  }
  .beard-name-jumbo {
    display: none;
  }
  #donate-view {
    height:65vh;
  }
  #post-event-amount {
    border: 1px solid blue;
  }
  .fb-post-embeds iframe {
    margin: 0 auto 2rem auto
  }

@media (min-width: 565px) {
  .announcement {
    margin: 0 5vw;
    padding: 10vh 0;
  }
  .stylishbutton {
    grid-row: 16/17;
    grid-column: 7/10;
    font-size: calc(8px + 6 * ((100vw - 320px) / 680));
    padding: 2vw 4vw;
  }
  .performers > div, .vendor-logos > div {
    width:50%;
    padding:1rem;
  }
  .header-image {
    width: 20%;
    height: auto;
  }
}

@media (min-width: 1025px) {
  header {
    height:25vh;
    padding-bottom:5vh;
  }
  .body {
    max-width: 80vw;
  }
  header {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
  }

  h3 {
    font-size: 1.5rem;
  }
  .header-image {
    width: 20%;
  }
  #hero-img {
    max-width:80%;
    margin:0 auto;
    height:auto;
  }
  .announcement {
    padding: 10vh 0;
  }
  .stylishbutton {
    grid-row: 16/17;
    grid-column: 7/10;
    font-size: calc(8px + 6 * ((100vw - 320px) / 680));
    padding: 2vh 2vw;
  }
  .charity-logo {
    width:25%;
    padding:0 2vw;
  }
  #email-form {
    margin: 10vh 0;
    padding: 10vh;
    /* border: 2px solid black; */
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.551)
  }
  .beard-row {
    max-height:25vh;
    height:22vh;
  }
  .beard-row > * {
    margin: 0 0.5vw;
  }
  .beard-icon-container {
    width: 10vw;
    margin: 0 auto;
  }
  .beard-icon {
    border-radius: 50%;
    border: 3px solid black;
    height:15vh;
    width:15vh;
  }
  .kitchen-sink {
    width:70vw;
    height:20vh;
    display:flex;
    flex-direction: row;
    align-items: center;
  }
  .kitchen-sink > * {
    margin: 0 auto;
  }
  .graph-container {
    width:35vw;
    height:15vh;
  }
  .bar-graph {
    background-color: #0d01f1;
    color:white;
    height:100%;
    width:100%;
  }
  .graph-amount {
    height:100%;
    margin: auto;
    display:inline-block;
    font-size: 5rem;
    font-weight:bold;
    padding:0.5vh;
  }
  .beardChart-name {
    display: none;
  }
  .beard-name-jumbo {
    margin: 0;
    display:block;
    width:14vw;
  }
  .beard-name-jumbo h4 {
    font-size:3rem;
    width:14vw;
  }
  .donate-button-container {
    height:18vh;
    max-height:18vh;
    width:50vw;
  }
  .donate-button-container > * {
    margin:0 1vw;
    width:50vw;
  }
  .suggested-donation {
    max-width:15vw;
    font-size: small;
  }
  .beard-label {
    margin-left:5%;
    width:70%;
    display: flex;
    flex-direction:column;
    text-align: left;
  }
  .beardChart-icon-container, .icon-and-name {
    width:8vw;
  }
  .chart-container {
    width:75vw;
  }
  .beardChart-name {
    font-size: large;
  }
  .stream-embed {
    width:100%;
    height:80vh;
  }
  .performers > div, .vendor-logos > div {
    width:33%;
  }
}
